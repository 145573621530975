import axios from '@axios'

export default {
  namespaced: true,
  state: {
    tags: [],
  },
  getters: {},
  mutations: {},
  actions: {
    getTags() {
      return new Promise((resolve, reject) => {
        axios
          .get('http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/getTags')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    getLanguages() {
      return new Promise((resolve, reject) => {
        axios
          .get('http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/getLanguages')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    getClassification() {
      return new Promise((resolve, reject) => {
        axios
          .get('http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/getClassifications')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    getSubClassification() {
      return new Promise((resolve, reject) => {
        axios
          .get('http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/getClassifications')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    getDonationCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('http://entities-microsevice.muslimdo.com/api/internalops/donationcatergories/list', queryParams)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
